import React, { Context, createContext, useReducer, useEffect } from "react";

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()
import {holes, clubs} from "../constants"

const initialState = {
	theme: 'light',
	location: 1,
	currentHole:0,
	scores: [],
	players : ["", null, null, null],
	completedRules: false,
	interludes: 0,
	showWellDone:false,
	wellDoneBottom: "Well Done",
	wellDoneTop: "a little message",
	showCog : false,
	showBack : false,
	played: 0,
	showTray:false,
	course: 2,
	selfieImage: null,
	gameID: null

}



function reducer(state, action){

	switch(action.type){
		case 'UPDATE_WELLDONE_TOP': {
			return{
				...state,
				wellDoneTop: action.payload
			}
		}
		case 'UPDATE_GAME_ID': {
			return{
				...state,
				gameID: action.payload
			}
		}		
		case 'UPDATE_WELLDONE_BOTTOM': {
			return{
				...state,
				wellDoneBottom: action.payload
			}
		}	
		case 'UPDATE_SELFIE_IMAGE': {
			return{
				...state,
				selfieImage: action.payload
			}
		}			
		case 'SHOW_BACK': {
			console.log("SHOWBACK")
			console.log(action.payload)
			return{
				...state,
				showBack: action.payload
			}
		}		
		case 'SHOW_COG': {
			return{
				...state,
				showCog: action.payload
			}
		}	
		case 'CHANGE_COURSE': {

		var new_theme = "light";
			if (state.course == 2 && state.location == 2){
				new_theme = "coney"
			}else if (state.course == 1 && state.location == 2){
				new_theme = "beach"
			} else{
new_theme = "light";
			}
				
			return{
				...state,
				course: action.payload,
				theme: new_theme
			}
		}			
		case 'UPDATE_PLAYED': {
			return{
				...state,
				played: action.payload
			}
		}	
		case 'COMPLETED_RULES': {
			return{
				...state,
				completedRules: true
			}
		}		
		case 'TOGGLE_WELLDONE': {
			return{
				...state,
				showWellDone: action.payload
			}
		}		
		case 'SET_INTERLUDES': {
			return{
				...state,
				interludes: action.payload
			}
		}		
		case 'SET_CURRENT_LOCATION': {
			return{
				...state,
				location: action.payload
			}
		}	
		case 'SET_CURRENT_SCORE': {
			return{
				...state,
				scores: action.payload
			}
		}	
		case 'SET_CURRENT_PLAYERS': {

				var scores = []
				var arr = []
				
				var currentHoles = clubs[state.location].holes[state.course].hole_config
				
				
				if (state.players !== undefined){ 
				for (var j = 0; j < currentHoles.length; j++){
					var arr = [] 
					for (var i = 0; i < state.players.length ; i++) {
					 arr.push(currentHoles[j].par)
					 console.log(arr);
					  } 
					  scores.push(arr);
					}
				}
				
				
		



			return{
				...state,
				players: action.payload,
				scores: scores
			}
		}
		case 'SET_CURRENT_HOLE': {
			return{
				...state,
				currentHole: action.payload
			}
		}	
		case 'SHOW_TRAY': {
			return{
				...state,
				showTray: action.payload
			}
		}			
										
		default:
			throw new Error('Bad Action Type')
	}
}

const GlobalContextProvider = ({children}) => {

	const [state, dispatch] = React.useReducer(reducer, initialState, (state) => {
        const persistedScores = localStorage.getItem("SCORES")
		const scores = persistedScores ? JSON.parse(persistedScores) : []
		
        const persistedLocation = localStorage.getItem("LOCATION")
		const location = persistedLocation ? JSON.parse(persistedLocation) : 0

        const persistedGameID = localStorage.getItem("GAMEID")
		const gameID = persistedGameID ? JSON.parse(persistedGameID) : null

        const persistedCourse = localStorage.getItem("COURSE")
		const course = persistedCourse ? JSON.parse(persistedCourse) : 0

        const persistedPlayers = localStorage.getItem("PLAYERS")
		const players = persistedPlayers ? JSON.parse(persistedPlayers) : ["", null,null,null]

		const persistedCurrentHole = localStorage.getItem("CURRENT_HOLE")
		const currentHole = persistedCurrentHole ? JSON.parse(persistedCurrentHole) : 0

		const persistedplayed = localStorage.getItem("PLAYED")
		const played = persistedplayed? JSON.parse(persistedCurrentHole) : 0

        return { ...state, scores, location, gameID, players, played, course, currentHole}
	})
	useEffect(() => {
        localStorage.setItem("LOCATION", JSON.stringify(state.location))
    }, [state.location])

	useEffect(() => {
        localStorage.setItem("PLAYERS", JSON.stringify(state.players))
    }, [state.players])
	useEffect(() => {
        localStorage.setItem("GAMEID", JSON.stringify(state.gameID))
    }, [state.gameID])
	
	useEffect(() => {
        localStorage.setItem("SCORES", JSON.stringify(state.scores))
	}, [state.scores])
 
	useEffect(() => {
        localStorage.setItem("COURSE", JSON.stringify(state.course))
	}, [state.course])
	
	useEffect(() => {
        localStorage.setItem("CURRENT_HOLE", JSON.stringify(state.currentHole))
	}, [state.currentHole])
	useEffect(() => {
        localStorage.setItem("PLAYED", JSON.stringify(state.played))
	}, [state.played])
	return (
	<GlobalStateContext.Provider value={state}>
		<GlobalDispatchContext.Provider value={dispatch}>
			{children}
		</GlobalDispatchContext.Provider>
	</GlobalStateContext.Provider>
	);
}

export default GlobalContextProvider